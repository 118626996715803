.footer {
  background-color: #3c4a67;

  &__content {
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include r(820) {
      flex-wrap: wrap;
      padding: 34px 0 20px 0;
    }
  }

  &__logo {
    display: inline-block;
    font-size: 0;
    transition: .3s;

    @include r(820) {
      position: absolute;
      top: 22px;
      left: 0;
    }

    &:hover {
      opacity: .8;
      transition: .3s;
    }

    img {
      max-width: 74px;
      width: 100%;
      @include r(820) {
        max-width: 43px;
      }
    }
  }

  &__contacts {
    display: block;
    text-align: center;
    @include r(820) {
      order: 2;
      flex: 0 0 100%;
      padding: 30px 0 0 0;
      @include r(620) {
        display: block;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      @include r(620) {
        display: block;
      }

      li {
        display: inline-block;
        margin-right: 35px;
        @include r(620) {
          margin: 0 0 12px 0;
          display: block;
        }

        &:last-child {
          margin-right: 0;
          @include r(620) {
            margin-bottom: 0;
          }
        }

        a {
          color: #fff;
          font-family: "Gotham Pro";
          font-size: 16px;
          font-weight: 300;
          line-height: 1;
          transition: .3s;

          &:hover {
            color: #898989;
            transition: .3s;
          }
        }
      }
    }
    .main {
      &__text {
        display: block;
        margin-top: 18px;
        color: #fff;
        font-weight: 300;
        font-family: "Gotham Pro";
      }
    }
  }

  &__social {
    display: block;
    @include r(820) {
      order: 1;
      flex: 0 0 100%;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include r(820) {
        justify-content: center;
      }

      li {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}