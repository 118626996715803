.jobs-resume {
  max-width: 1166px;
  width: 100%;
  background-color: #021422;
  margin: 0 auto 70px auto;

  .container {
    max-width: 970px;
  }

  .main {
    &__title {
      h2 {
        color: #fff;
      }
    }
    &__text {
      color: #fff;
      display: block;
      margin-top: 40px;
      text-align: left;
    }
  }
}