.gallery {
  .container {
    max-width: 860px;
  }
  .main {
    &__content {
      position: relative;
      .parallax-img {
        max-width: 300px;
        top: 30px;
        right: -200px;
      }
    }
  }
  &__photos {
    display: flex;
    margin: 40px -12px 0 -12px;
    @include r(700) {
     flex-wrap: wrap;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;

    &--sm {
      flex: 0 0 66.6666667%;

      @include r(700) {
        flex: 0 0 100%;
      }

      .gallery {

        &__item {
          flex: 0 0 50%;
          padding: 12px;
          @include r(500) {
            flex: 0 0 100%;
          }
        }
        &__inner {
          display: block;
          width: 100%;
          height: 147px;
          background: {
            repeat: no-repeat;
            size: cover;
            position: center;
          }
          &--hidden {
            display: none;
          }
        }
      }
    }

    &--lg {
      flex: 0 0 33.3333333%;
      @include r(700) {
        flex: 0 0 100%;
      }

      .gallery {
        &__item {
          flex: 0 0 100%;
          padding: 12px;
        }
        &__inner {
          display: block;
          width: 100%;
          height: 319px;
          background: {
            repeat: no-repeat;
            size: cover;
            position: center;
          }
          @include r(700) {
            height: 200px;
          }
          @include r(500) {
            height: 147px;
          }
          &--hidden {
            display: none;
          }
        }
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#31405d, .7);
    padding: 8px 10px;
    z-index: 2;
    height: 38px;
    transition: .3s;

    span {
      display: inline-block;
      text-align: center;
      color: #fefefe;
      font-family: "SFUI Display";
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
    }
  }
  &__inner {
    position: relative;

    &:hover {
      .gallery {
        &__title {
          height: 100%;
          transition: .3s;
        }
      }
    }
  }

  &--home {
    .gallery {
      &__items {
        &--lg {
          @include r(700) {
            display: none;
          }
        }

        &--sm {
          @include r(700) {
            flex: 0 0 100%;
          }

          .gallery {
            &__item {
              @include r(500) {
                flex: 0 0 100%;
              }
            }
          }
        }
      }
    }
  }
}

.lightbox {
  .lb-data {
    display: flex;
    align-items: center;
    margin: 5px 0 0 0;
    justify-content: center;
    .lb-caption {
      color: #fff;
      font-family: "SFUI Display";
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
    }
    .lb-number {
      color: #fff;
      font-family: "SFUI Display";
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      padding: 0 0 0 6px;
    }
    .lb-details {
      display: flex;
      align-items: center;
      float: none;
      justify-content: center;
      width: 100%;
    }
  }
  .lb-closeContainer {
    display: inline-block;

    .lb-close {
      float: none;
      width: 40px;
      height: 40px;
      background: {
        image: url("#{$images-path}/icons/close-gallery.png");
        repeat: no-repeat;
        size: contain;
      }
      opacity: 1;
      transition: .3s;

      &:hover {
        opacity: .8;
        transition: .3s;
      }
    }
  }
  .lb-outerContainer {
    .lb-image {
      border: 0;
      border-radius: 0;
    }
  }
  .lb-nav {
    .lb-prev {
      background: {
        image: url("#{$images-path}/icons/arrow-left-white.svg");
        size: 70px;
        position: left 20px center;
      }
    }
    .lb-next {
      background: {
        image: url("#{$images-path}/icons/arrow-right-white.svg");
        size: 70px;
        position: right 20px center;
      }
    }
  }
}