.jobs-inners {

  .main {
    &__content {
      padding-top: 0;
    }
  }

  &__text {
    color: #000;
    display: block;
    text-align: center;
  }
  .container {
    max-width: 970px;
  }

  &__items {
    display: block;
    margin-top: 70px;
  }

  &__item {
    display: block;
    position: relative;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    .main {
      &__text {
        display: block;
      }
    }

    &:nth-child(odd) {
      background-color: #f1e0e0;
    }

    &:nth-child(even) {
      background-color: #d6d9df;
    }

    &-header {
      display: block;
      padding: 22px 28px;
      cursor: pointer;

      &::before {
        position: absolute;
        content: '';
        top: 25px;
        left: 50%;
        width: 26px;
        height: 26px;
        margin-left: -13px;
        background: {
          image: url("#{$images-path}/icons/down-arrow.svg");
          repeat: no-repeat;
          size: contain;
        }
        @include r(750) {
          left: inherit;
          right: 20px;
          margin-left: 0;
        }
      }

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: {
          image: url("#{$images-path}/icons/up-arrow.svg");
          repeat: no-repeat;
          position: center;
          size: 26px;
        }
        opacity: 0;
        transition: .1s;
      }

      &.open {
        &::after {
          opacity: 1;
          transition-delay: .3s;
          transition-duration: .3s;
        }
      }
    }

    &-body {
      display: none;
      padding: 10px 28px 50px 28px;

      ul {
        display: block;
        margin-top: 5px;

        li {
          display: block;
          margin-top: 5px;
        }
      }
    }
  }
}