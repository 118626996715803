.home-instagram {
  .container {
    max-width: 950px;
  }
  .main {
    &__title {

      img {
        max-width: 32px;
        width: 100%;
      }
      h2 {
        margin-top: 26px;
      }
    }
  }
  &__widget {
    display: block;
    margin-top: 60px;
  }

  .main {
    &__action {
      margin-top: 70px;
    }
    &__content {
      position: relative;
      .parallax-img {
        left: -20px;
        bottom: -1600px;
        max-width: 266px;
        transform: rotate(30deg);
      }
    }
  }
}