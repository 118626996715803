.field-input {
  height: 38px;
  background-color: #fff;
  display: block;
  width: 100%;
  padding: 0 12px;
  color: #898989;
  font-family: "SFUI Display";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.08px;
  outline: none;

  &::placeholder {
    color: #898989;
  }
}

.field-textarea {
  height: 79px;
  background-color: #fff;
  display: block;
  width: 100%;
  padding: 10px 12px;
  color: #898989;
  font-family: "SFUI Display";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.08px;
  outline: none;
  resize: none;

  &::placeholder {
    color: #898989;
  }
}

.field-select {
  height: 38px;
  background-color: #fff;
  display: block;
  border: 0;
  width: 100%;
  padding: 0 12px;
  color: #898989;
  font-family: "SFUI Display";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.08px;
  outline: none;

  &::placeholder {
    color: #898989;
  }
}

.field-file {
  position: relative;
  input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .btn-form {
    @include r(400) {
      line-height: 1;
      padding: 11px 4px;
      font-size: 14px;
      height: auto;
    }
  }
}