.home-request {
  &__holder {
    display: flex;
    margin: 0 auto;
    max-width: 620px;
    align-content: stretch;
  }

  &__photo {
    display: block;
    flex: 1 1 auto;
    margin-right: 10px;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    @include r(600) {
      display: none;
    }
  }

  &__form {
    flex: 0 0 332px;

    @include r(600) {
      flex: 0 0 100%;
      box-shadow: 3px 4px 40px rgba(116, 116, 116, 0.75);
    }
  }
}