.about {
  .container {
    max-width: 1000px;
  }
  &-request {
    .main {
      &__content {
        padding-top: 0;
      }
    }
  }

  &__holder {
    display: flex;
    align-items: stretch;
    margin-bottom: 60px;

    @include r(750) {
      display: block;
      margin-bottom: 40px;
    }
    @include r(600) {
      margin-bottom: 30px;
    }

    &--right {
      .about {
        &__description {
          order: 2;
        }
        &__photo {
          order: 1;
          margin: 0 60px 0 0;
        }
      }
    }
  }

  &__description {
    flex: 0 0 55%;

    .main {
      &__title {
        margin-bottom: 5px;
        h2 {
          text-align: left;
        }
        &-bold {
          margin-bottom: 28px;
          color: #d16566;
        }
      }
    }
  }

  &__photo {
    flex: 0 0 calc(45% - 60px);
    margin-left: 60px;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
    min-height: 360px;
    @include r(750) {
      min-height: 240px;
      max-width: 500px;
      margin: 20px auto 0 auto !important;
    }
  }

  &__mission {
    display: flex;
    align-items: stretch;

    @include r(830) {
      display: block;
    }

    &-photo {
      flex: 0 0 330px;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @include r(830) {
        padding: 20px;
      }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#021422, .55);
        z-index: 1;
      }

      img {
        display: block;
        max-width: 60px;
        margin: 0 auto;
      }

      .main {
        &__title {
          &-bold {
            display: block;
            color: #fff;
            margin-top: 30px;
            text-align: center;
            line-height: 1.8;
            @include r(830) {
              margin-top: 15px;
            }

            br {
              @include r(830) {
                display: none;
              }
            }
          }
        }
      }
    }
    &-description {
      padding: 30px 24px;
      background-color: #021422;
      .main {
        &__text {
          color: #fff;
        }
      }
    }

    &-title {
      position: relative;
      z-index: 2;
    }
  }
  .main {
    &__action {
      margin-top: 60px;
    }
  }
}