.service {
  &--description {
    background-color: #31405d;

    .container {
      max-width: 1010px;
    }

    .main {
      &__content {
        padding: 46px 0 65px 0;

        @include r(960) {
          padding: 20px 0 40px 0;
        }
      }
      &__text {
        color: #fff;
        line-height: 1.6;
        strong {
          font-weight: 700;
        }
      }
    }
    &--pt {
      .main {
        &__content {
          padding: 30px 0;
        }
      }
    }
  }

  &--experience {
    .container {
      max-width: 1010px;
    }
    .service {
      &__text {
        margin: 50px auto 0 auto;
        max-width: 940px;
        text-align: left;
      }

      &__items {
        display: flex;
        margin: 80px 0;
        align-items: center;
        justify-content: space-between;
        padding: 70px 0;
        position: relative;

        &::before {
          width: calc(100% - 3px);
          height: 1px;
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          background-color: #90aebb;
          z-index: 1;
          @include r(1023) {
            display: none;
          }
        }
        @include r(1200) {
          margin: 80px;
        }
        @include r(1023) {
          display: block;
          padding: 0;
          margin: 50px 0 0 0;
        }
      }

      &__item {
        flex: 0 0 57px;
        position: relative;
        padding: 120px 0;
        z-index: 2;
        @include r(1023) {
          display: flex;
          align-items: flex-start;
          padding: 0 0 60px 0;
          position: relative;

          &::before {
            height: 100%;
            width: 1px;
            left: 28px;
            top: 0;
            content: '';
            position: absolute;
            background-color: #90aebb;
            z-index: 1;
          }

          &:last-child {
            margin-bottom: 0;
            @include r(1023) {
              &::before {
                display: none;
              }
            }
          }
        }
        &-year {
          display: block;
          position: relative;
          width: 57px;
          height: 57px;
          border-radius: 50%;
          color: #021422;
          font-family: "SFUI Display";
          font-size: 16px;
          text-align: center;
          font-weight: 400;
          line-height: 57px;
          background-color: #90aebb;
          @include r(1023) {
            flex: 0 0 57px;
            z-index: 2;
          }

          &::before {
            width: 1px;
            height: 100px;
            content: '';
            position: absolute;
            left: 50%;
            background-color: #90aebb;
            @include r(1023) {
              width: 60px;
              height: 1px;
              left: 100%;
              top: 50% !important;
              bottom: inherit !important;
            }
          }
        }
        &-description {
          position: absolute;
          text-align: center;
          width: 300px;
          left: 50%;
          transform: translateX(-50%);
          @include r(1200) {
            width: 270px;
          }
          @include r(1023) {
            position: relative;
            width: 100%;
            left: 0;
            transform: none;
            padding: 18px 0 0 70px;
          }

          strong {
            display: block;
            color: #31405d;
            font-family: "SFUI Display";
            font-size: 20px;
            font-weight: 700;
            br {
              @include r(1023) {
                display: none;
              }
            }
          }
          p {
            display: block;
            margin-top: 5px;
            color: #939393;

            br {
              @include r(1023) {
                display: none;
              }
            }
          }
        }

        &:nth-child(odd) {
          .service {
            &__item {
              &-year {
                &::before {
                  top: 100%;
                }
              }
              &-description {
                top: 100%;
              }
            }
          }
        }

        &:nth-child(even) {
          .service {
            &__item {
              &-year {
                &::before {
                  bottom: 100%;
                }
              }
              &-description {
                bottom: 100%;
              }
            }
          }
        }
      }
    }
  }

  &--types {
    .container {
      max-width: 1010px;
    }
    .main {
      &__title {
        h2 {
          font-size: 28px;
        }

        @include r(630) {
          display: none;
        }
      }
    }
    .service {

      &__center {
        display: block;
        text-align: center;
      }
      &__items {
        display: inline-block;
        margin-top: 70px;
        max-width: 550px;

        @include r(900) {
          margin-top: 50px;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }

        &-icon {
          flex: 0 0 24px;

          img {
            max-width: 100%;
          }
        }
        &-description {
          flex: 1 1 auto;
          padding-left: 38px;
        }
      }

      &__team {
        display: flex;
        align-items: center;
        margin-top: 60px;
        position: relative;

        ul {
          display: block;
          padding-left: 60px;

          @include r(700) {
            padding-left: 30px;
          }
          @include r(600) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }

          li {
            display: block;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
            .main {
              &__text {
                @include r(600) {
                  color: #fff;
                }
              }
            }
          }
        }

        &-photo {
          flex: 0 0 45%;
          height: 300px;
          background: {
            repeat: no-repeat;
            size: cover;
            position: center;
          }

          @include r(600) {
            flex: 0 0 100%;
            background-position: center right;
          }
        }
      }

      &__tender {
        display: flex;
        position: relative;

        .main {
          &__text {
            flex: 0 0 475px;

            @include r(900) {
              flex: 0 0 60%;
            }
            @include r(500) {
              flex: 0 0 100%;
              padding-right: 100px;
            }
            @include r(400) {
              padding-right: 70px;
            }
          }
        }

        &-img {
          position: absolute;
          bottom: -60px;
          left: calc(100% - 380px);
          max-width: 500px;

          @include r(1200) {
            transform: none !important;
          }

          @include r(900) {
            max-width: 400px;
            bottom: -20px;
            left: calc(100% - 280px);
          }
          @include r(700) {
            max-width: 300px;
            bottom: inherit;
            top: -40px;
            left: calc(100% - 180px);
          }
          @include r(500) {
            left: calc(100% - 100px);
          }
          @include r(400) {
            left: calc(100% - 80px);
          }
        }
      }

      &__cook {
        display: flex;
        align-items: center;
        margin-top: 60px;

        @include r(650) {
          display: block;
          margin-top: 30px;
        }
        @include r(630) {
          margin-top: 0;
        }

        ul {
          display: block;
          padding-left: 40px;
          @include r(650) {
            padding: 30px 0 0 0 !important;
          }

          li {
            display: block;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &-photo {
          flex: 0 0 45%;
          height: 300px;
          background: {
            repeat: no-repeat;
            size: cover;
            position: center;
          }
          @include r(650) {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
            height: 220px;
          }
        }

        &--right {
          .service {
            &__cook {
              &-photo {
                order: 2;
              }
            }
          }
          ul {
            padding: 0 40px 0 0;
            order: 1;
          }
        }
      }
    }
  }

  &--bg {
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
      attachment: fixed;
    }
    height: 150px;

    @include r(1000) {
      background-attachment: inherit;
    }
  }

  &--cnt {
    .main {
      &__content {
        padding-top: 0;
      }
    }

    @include r(900) {
      display: none;
    }
  }

  &--title {
    .main {
      &__title {
        &-light {
          text-align: center;
          font-size: 28px;
          strong {
            color: #b76364;
            font-weight: 700;
          }
        }
      }
    }
  }

  &--items {
    margin-top: -43px;
    position: relative;
    z-index: 2;

    .container {
      max-width: 1010px;
    }

    .main {
      &__content {
        padding-top: 0;
      }
    }
    .service {
      &__items {
        display: flex;
        align-items: stretch;
        margin: 0 -15px;
        @include r(960) {
          flex-wrap: wrap;
        }

        &--fours {
          .service {
            &__item {
              flex: 0 0 25%;

              @include r(960) {
                flex: 0 0 50%;
              }

              @include r(550) {
                flex: 0 0 100%;
              }
            }
          }
        }
        &--three {
          .service {
            &__item {
              flex: 0 0 33.3333333%;

              @include r(960) {
                flex: 0 0 50%;
              }

              @include r(550) {
                flex: 0 0 100%;
              }
            }
          }
        }
      }
      &__item {
        flex: 1 1 auto;
        padding: 15px;

        &-inner {
          box-shadow: 0 -2px 40px rgba(116, 116, 116, 0.75);
          border: 1px solid #e1e1e1;
          background-color: #fff;
          display: block;
          padding: 20px 14px 20px 14px;
          height: 100%;
        }

        &-icon {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 100%;
            display: block;
          }
        }

        .main {
          &__text {
            margin-top: 22px;
            text-align: center;
            line-height: 1.8;
          }
        }
      }
    }
  }

  &--trust {
    .main {
      &__content {
        @include r(600) {
          padding-bottom: 0;
        }
      }
    }
    .service {
      &__slider {
        padding: 0 120px;
        position: relative;
        margin: 50px 0 0 0;

        @include r(600) {
          padding: 0 60px;
        }

        .swiper-wrapper {
          align-items: center;
        }
        .swiper-slide {
          img {
            max-width: 100%;
          }
        }

        .swiper-button-prev {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -30px;
          width: 72px;
          height: 59px;
          background: {
            repeat: no-repeat;
            size: contain;
            image: url("#{$images-path}/icons/arrow-left-black.svg");
          }
          @include r(600) {
            width: 43px;
            height: 35px;
            margin-top: -17px;
          }
        }
        .swiper-button-next {
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -30px;
          width: 72px;
          height: 59px;
          background: {
            repeat: no-repeat;
            size: contain;
            image: url("#{$images-path}/icons/arrow-right-black.svg");
          }
          @include r(600) {
            width: 43px;
            height: 35px;
            margin-top: -17px;
          }
        }
      }
    }
  }

  &--choice {
    @include r(650) {
      background-color: #ebeced;
    }
    .container {
      max-width: 1210px;
    }
    .service {
      &__holder {
        background-color: #ebeced;
        padding: 60px 100px;

        @include r(1100) {
          padding: 50px 36px;
        }
        @include r(650) {
          background-color: transparent;
          padding: 40px 0;
        }

      }
      &__items {
        display: flex;
        align-items: stretch;
        margin: 30px -18px 0 -18px;
        flex-wrap: wrap;
      }
      &__item {
        padding: 18px;
        flex: 0 0 33.3333333%;

        @include r(900) {
          flex: 0 0 50%;
        }
        @include r(650) {
          flex: 0 0 100%;
        }

        &-inner {
          box-shadow: 0 -2px 40px rgba(116, 116, 116, 0.75);
          border: 1px solid #e1e1e1;
          background-color: #fff;
          display: block;
          padding: 20px 14px 20px 14px;
          height: 100%;
        }

        &-icon {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 100%;
            display: block;
          }
        }

        .main {
          &__text {
            margin-top: 22px;
            text-align: center;
            line-height: 1.8;
          }
        }
      }
    }
  }

  &--advantages {
    .container {
      max-width: 1200px;
    }

    .main {
      &__content {
        padding-bottom: 100px;

        @include r(800) {
          padding-bottom: 50px;
        }
      }
    }
    .service {
      &__holder {
        display: flex;
        background-color: #31405d;
        align-items: stretch;

        @include r(700) {
          display: block;
          margin-top: 100px;
        }
      }

      &__photo {
        flex: 0 0 44%;
        background: {
          repeat: no-repeat;
          size: cover;
          position: center;
        }
        margin: -40px 0 -40px 70px;
        box-shadow: 0 -5px 40px rgba(0, 0, 0, 0.45);

        @include r(900) {
          flex: 0 0 40%;
          margin-left: 50px;
        }
        @include r(700) {
          width: 249px;
          height: 166px;
          box-shadow: 3px 4px 40px rgba(116, 116, 116, 0.75);
          margin-left: 15px;
          top: -90px;
          position: relative;
          margin-top: 0;
        }
      }

      &__description {
        flex: 1 1 auto;
        text-align: center;
        padding: 96px 70px;
        position: relative;

        @include r(900) {
          padding: 90px 40px;
        }

        @include r(700) {

          margin-top: -30px;
          padding: 0 20px 90px 20px;
        }

        &::after {
          position: absolute;
          content: '';
          bottom: -10px;
          right: 70px;
          width: 107px;
          height: 107px;
          background: {
            image: url("#{$images-path}/icons/fork-with-swirled-pasta.svg");
            repeat: no-repeat;
            size: contain;
          }

          @include r(700) {
            right: 50%;
            transform: translateX(50%);
          }
        }

        .main {
          &__title {
            &-light {
              color: #fff;
              font-weight: 500;
              strong {
                text-transform: uppercase;
                display: block;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }

  &--request {
    .container {
      max-width: 1200px;
    }

    .main {
      &__content {
        padding-bottom: 120px;

        @include r(1200) {
          padding-bottom: 70px;
        }

        @include r(800) {
          padding: 0 0 40px 0;
        }
      }
    }
    &--pt {
      .main {
        &__content {
          padding: 60px 0;
        }
      }
    }
    .service {
      &__holder {
        display: flex;
        background-color: #ebeced;
        align-items: stretch;

        @include r(800) {
          flex-wrap: wrap;
          background-color: #fff;
        }
      }

      &__form {
        flex: 0 0 330px;
        background: {
          repeat: no-repeat;
          size: cover;
          position: center;
        }
        margin: -40px 0 40px 160px;
        box-shadow: 0 -5px 40px rgba(0, 0, 0, 0.45);

        @include r(1000) {
          margin-left: 60px;
        }
        @include r(800) {
          order: 2;
          margin: 0 auto;
        }
      }

      &__description {
        flex: 1 1 auto;
        text-align: center;
        padding: 96px 70px;
        position: relative;

        @include r(1100) {
          padding: 70px 60px;
        }

        @include r(1000) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @include r(800) {
          order: 1;
          padding: 0 15px 40px 15px;
        }

        .main {
          &__text {
            text-align: center;
          }
        }

        &-img {
          position: absolute;
          top: 950px;
          left: 130px;
          transform: translateX(-50%);
          max-width: 400px;

          &--ss {
            top: 1350px;
            max-width: 550px;
            left: 110px;
          }

          @include r(1200) {
            top: 45%;
            left: 15%;
            transform: none !important;
          }

          @include r(1000) {
            display: none;
          }

          &--compass {
            max-width: 300px;
            top: 650px;
            left: 200px;

            @include r(1200) {
              top: 46%;
              left: 20%;
              transform: none !important;
            }
          }
          &--cakes {
            max-width: 600px;
            top: 700px;
            left: 100px;

            @include r(1200) {
              top: 56%;
              left: 6%;
              transform: none !important;
              max-width: 450px;
            }
          }
        }
      }
    }
  }

  &--another {
    @include r(800) {
      background-color: #ebeced;
    }
    .container {
      max-width: 1010px;
    }
  }
}