.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(#000, .8);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include r(830) {
      padding: 8px 0;
    }
  }

  &__logo {
    display: inline-block;
    font-size: 0;
    transition: .3s;

    &:hover {
      opacity: .8;
      transition: .3s;
    }

    img {
      max-width: 74px;
      width: 100%;

      @include r(1000) {
        max-width: 60px;
      }
      @include r(840) {
        max-width: 43px;
      }
    }
  }

  &__contacts {
    display: block;
  }

  &__phone {
    display: inline-block;
    color: #fff;
    font-family: "Gotham Pro";
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    transition: .3s;

    @include r(1000) {
      font-size: 14px;
    }
    @include r(830) {
      font-size: 16px;
    }

    &:hover {
      color: #b76364;
      transition: .3s;
    }
  }

  &__social {
    display: block;
    margin-top: 16px;
    @include r(1000) {
      margin-top: 10px;
    }
    @include r(830) {
      margin-top: 6px;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include r(830) {
        justify-content: center;
      }

      li {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__burger {
    display: none;
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 20;
    transition: .3s;
    margin-left: 20px;
    flex: 0 0 auto;

    @include r(830) {
      display: block;
    }

    &::before {
      content: '';
      width: 18px;
      left: 50%;
      margin-left: -9px;
      height: 2px;
      top: 10px;
      position: absolute;
      background-color: #000;
      transition: .3s;
    }

    &::after {
      content: '';
      width: 18px;
      left: 50%;
      margin-left: -9px;
      height: 2px;
      bottom: 10px;
      position: absolute;
      background-color: #000;
      transition: .3s;
    }

    span {
      width: 18px;
      height: 2px;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -9px;
      position: absolute;
      background-color: #000;
      transition: .3s;
    }

    &:hover {
      &::before, &:after {
        opacity: 0;
        transition: .3s;
      }
      span {
        &:nth-child(1) {
          transform: rotate(30deg);
          transition: .3s;
        }
        &:nth-child(2) {
          transform: rotate(-30deg);
          transition: .3s;
        }
      }
    }

    &.is-open {
      &::before, &:after {
        opacity: 0;
        transition: .3s;
      }
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          transition: .3s;
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
          transition: .3s;
        }
      }
    }
  }
}