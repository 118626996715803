.lifehacks {

  .container {
    max-width: 950px;
  }
  .main__pagination {
    margin: 50px 0 0 -20px;

    @include r(1100) {
      margin-left: 0;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 40px -13px 0 -13px;
  }
  &__item {
    flex: 0 0 33.3333333%;
    padding: 13px;

    @include r(850) {
      flex: 0 0 50%;
    }
    @include r(600) {
      flex: 0 0 100%;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    padding: 15px;
    transition: .3s;

    &:hover {
      transform: scale(1.07);
      transition: .3s;
    }
  }

  &__top {
    display: block;
    padding: 0 4px;
  }

  &__bottom {
    display: block;
    margin-top: 18px;

    span {
      display: block;
      color: #898989;
      font-family: "SFUI Display";
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      padding: 0 4px;
    }
  }

  &__photo {
    display: block;
    width: 100%;
    height: 147px;
    margin-top: 12px;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }

  &--home {
    .lifehacks {
      &__item {
        &:last-child {
          @include r(850) {
            display: none;
          }
        }
      }
    }
  }

}