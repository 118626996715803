.navigation {
  display: block;

  @include r(830) {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    display: block;
    z-index: 10;
    transform: translateX(100%);
    visibility: hidden;
    background-color: #38445c;
    padding: 28px 0 100px 0;
    transition: .4s;

    &.is-open {
      transform: translateX(0);
      visibility: visible;
      transition: .4s;
    }
  }

  &__drop {
    display: none;
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 13px;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/plus.svg");
      repeat: no-repeat;
      size: 14px;
      position: center;
    }
    cursor: pointer;

    &.is-open {
      background-image: url("#{$images-path}/icons/minus.png");
    }
    @include r(830) {
      display: block;
    }
  }

  &__title {
    display: none;
    padding: 0 18px;
    color: #fff;
    font-family: "Gotham Pro";
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;

    @include r(830) {
      display: block;
    }
  }

  & > ul {
    display: flex;
    @include r(830) {
      display: block;
      margin-top: 40px;
    }

    & > li {
      display: inline-block;
      margin-right: 28px;
      position: relative;

      @include r(1000) {
        margin-right: 18px;
      }
      @include r(830) {
        display: block;
        margin: 0;

        &:last-child {
          & > a {
            border-bottom: 0;
          }
        }
      }

      &:hover {
        & > a, span {
          color: #b76364;
          transition: .3s;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      & > a, span {
        display: block;
        color: #fff;
        font-family: "Gotham Pro";
        font-size: 16px;
        font-weight: 300;
        transition: .3s;
        line-height: 100px;
        cursor: pointer;

        @include r(1000) {
          font-size: 14px;
          line-height: 90px;
        }
        @include r(830) {
          line-height: 1;
          padding: 14px 18px;
          border-bottom: 1px solid #73809d;
        }

        &:hover {
          color: #b76364;
          transition: .3s;
        }
      }

      & > ul {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: rgba(#000, .8);
        padding: 16px 16px;
        opacity: 0;
        visibility: hidden;
        transition: .3s;

        @include r(830) {
          position: relative;
          top: 0;
          display: none;
          transition: none;
          visibility: visible;
          padding: 0;
          opacity: 1;
          background-color: transparent;
        }

        li {
          display: block;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
          @include r(830) {
            margin: 0;
          }

          a {
            color: #fefefe;
            font-family: "Gotham Pro";
            font-size: 16px;
            font-weight: 300;
            white-space: nowrap;
            padding: 5px 0;
            line-height: 1;
            transition: .3s;

            @include r(1000) {
              font-size: 14px;
            }

            @include r(830) {
              display: block;
              line-height: 1;
              padding: 14px 18px;
              border-bottom: 1px solid #73809d;
              white-space: normal;
            }

            &:hover {
              color: #b76364;
              transition: .3s;
            }
          }
        }
      }

      &:hover {
        ul {
          opacity: 1;
          visibility: visible;
          transition: .3s;

          @include r(830) {
            transition: none;
          }
        }
      }
    }
  }
}