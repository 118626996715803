.btn-more {
  display: inline-block;
  position: relative;
  color: #010101;
  font-family: "Gotham Pro";
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  border: {
    width: 1px;
    style: solid;
    color: rgba(#000, .6);
  }
  padding: 13px 46px 14px 26px;
  outline: none;
  transition: .3s;

  &::before {
    position: absolute;
    content: '';
    width: 20px;
    height: 16px;
    background: {
      image: url("#{$images-path}/icons/arrow-right-black.svg");
      repeat: no-repeat;
      size: contain;
    }
    right: 16px;
    top: 50%;
    margin-top: -8px;
    opacity: 1;
    visibility: visible;
    transition: .3s;
  }

  &::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 17px;
    background: {
      image: url("#{$images-path}/icons/arrow-right-white.svg");
      repeat: no-repeat;
      size: contain;
    }
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &:hover {
    background-color: #38445c;
    border-color: #38445c;
    color: #fff;
    transition: .3s;

    &::before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }

    &::after {
      opacity: 1;
      visibility: visible;
      transition: .3s;
    }
  }

  &--light {
    background-color: transparent;
    border-color: #38445c;
    color: #fff;
    transition: .3s;

    &::before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }

    &::after {
      opacity: 1;
      visibility: visible;
      transition: .3s;
    }
  }
}

.btn-form {
  display: block;
  padding: 0 12px;
  height: 38px;
  border: 1px solid #fff;
  width: 100%;
  background-color: transparent;
  outline: none;
  text-align: center;
  color: #fff;
  font-family: "Gotham Pro";
  font-size: 16px;
  line-height: 35px;
  font-weight: 500;
  letter-spacing: -0.08px;
  transition: .3s;
  cursor: pointer;

  @include r(400) {
    font-size: 15px;
    padding: 0 4px;
  }

  &:hover {
    background-color: #fff;
    color: #b76364;
    transition: .3s;
  }
}

.btn-dark {
  display: block;
  width: 100%;
  border: {
    width: 1px;
    style: solid;
    color: rgba(#fff, .6);
  }
  background-color: rgba(#000, .8);
  color: #fff;
  font-family: "Gotham Pro";
  font-size: 16px;
  font-weight: 500;
  line-height: 43px;
  height: 45px;
  text-align: center;
  padding: 0 10px;
  transition: .3s;

  &:hover {
    border-color: #021422;
    background-color: #fff;
    color: #000;
    transition: .3s;
  }
}