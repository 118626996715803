.contacts {
  flex: 1 1 auto;
  .container {
    max-width: 970px;
  }

  &__content {
    padding: 70px 0;

    @include r(700) {
      padding: 50px 0;
    }
  }

  &__title {
    max-width: 300px;
    @include r(830) {
      text-align: center;
      max-width: 100%;
      margin-bottom: 80px;
    }

    &--mob {
      display: none;
      @include r(830) {
        display: block;
        order: 2;
        max-width: 276px;
        margin-bottom: 40px;
      }
      @include r(630) {
        max-width: 100%;
      }
    }

    &--desk {
      @include r(830) {
        display: none;
      }
    }
  }
  .main {
    &__title {
      margin-bottom: 60px;
      @include r(830) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: 35px;
      }
    }
  }

  &__holder {
    display: flex;
    margin-top: 40px;
    @include r(830) {
      flex-wrap: wrap;
      padding-left: 27vw;
    }
    @include r(630) {
      padding-left: 0;
      margin-top: 50px;
    }
  }

  &__form {
    flex: 0 0 276px;

    @include r(830) {
      order: 3;
    }

    @include r(630) {
      flex: 0 0 100%;
    }
  }

  &__description {
    flex: 1 1 auto;
    padding-left: 160px;
    position: relative;

    @include r(1000) {
      padding-left: 40px;
    }

    @include r(830) {
      order: 1;
      flex: 0 0 100%;
    }
    @include r(830) {
      padding-left: 0;
    }

    &-img {
      position: absolute;
      top: -100px;
      left: 340px;
      max-width: 340px;
      width: 100%;

      @include r(1200) {
        transform: none !important;
      }
      @include r(1000) {
        left: 200px;
      }
      @include r(830) {
        left: 190px;
        top: -40px;
      }
      @include r(730) {
        max-width: 230px;
      }
      @include r(440) {
        max-width: 200px;
        left: 130px;
      }
    }

    strong {
      font-weight: 700;
    }

    ul {
      display: block;
      margin: 14px 0 40px 0;

      &:last-child {
        margin-bottom: 0;
      }
      li {
        display: block;
        margin-top: 10px;

        a {
          color: #000;
          font-family: "SFUI Display";
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          transition: .3s;

          &:hover {
            color: #b76364;
            transition: .3s;
          }
        }
      }
    }
  }

}