.soc-instagram {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/instagram.svg");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 1;
    visibility: visible;
    transition: .3s;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/instagram-hover.svg");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &:hover {
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
    &::after {
      opacity: 1;
      visibility: visible;
      transition: .3s;
    }
  }
}

.soc-facebook {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/facebook.svg");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 1;
    visibility: visible;
    transition: .3s;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/facebook-hover.svg");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &:hover {
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
    &::after {
      opacity: 1;
      visibility: visible;
      transition: .3s;
    }
  }
}

.soc-mail {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/mail.svg");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 1;
    visibility: visible;
    transition: .3s;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: {
      image: url("#{$images-path}/icons/mail-hover.svg");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &:hover {
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
    &::after {
      opacity: 1;
      visibility: visible;
      transition: .3s;
    }
  }
}

.main {
  position: relative;

  &--page {
    padding-top: 100px;

    @include r(1000) {
      padding-top: 90px;
    }
    @include r(830) {
      padding-top: 65px;
    }
  }

  &--height {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    padding: 60px 0;

    @include r(700) {
      padding: 50px 0;
    }
  }

  &__title {
    display: block;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }

    h2 {
      display: block;
      color: #000;
      font-family: "Gotham Pro";
      font-size: 28px;
      font-weight: 500;
      line-height: 1.1;
    }
  }

  &__title-bold {
    color: #2c2c2c;
    font-family: "SFUI Display";
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
  }

  &__title-light {
    color: #2c2c2c;
    font-family: "SFUI Display";
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
  }

  &__text {
    color: #000;
    font-family: "SFUI Display";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;

    &--strong {
      font-weight: 700;
    }

    &--mt {
      margin-top: 40px;
    }
  }

  &__action {
    display: block;
    text-align: center;
    margin-top: 38px;

    button, a {
      display: inline-block;
    }
  }

  &__form {
    display: block;
    padding: 40px 30px;
    background-color: #b76364;

    &--service {
      box-shadow: 0 -5px 40px rgba(116, 116, 116, 0.75);
      background-color: #021422;
    }

    &--inline {
      @include r(580) {
        box-shadow: 3px 4px 40px rgba(116, 116, 116, 0.75);
      }
      .main {
        &__form {
          &-items {
            display: flex;
            align-items: center;
            margin: 0 -9px;
            @include r(1020) {
              flex-wrap: wrap;
            }
            @include r(580) {
              margin-top: 14px;
            }
          }
          &-item {
            padding: 20px 9px 9px 9px;
            flex: 1 1 auto;
            margin: 0;

            @include r(1020) {
              flex: 0 0 33.3333333%;
              padding: 9px;
            }
            @include r(800) {
              flex: 0 0 50%;
            }
            @include r(580) {
              flex: 0 0 100%;
              padding: 6px 9px;
            }
            &--button {
              flex: 0 0 234px;
              @include r(1020) {
                flex: 0 0 33.3333333%;
              }
              @include r(800) {
                flex: 0 0 50%;
              }
              @include r(580) {
                flex: 0 0 100%;
              }
            }
          }
        }
      }
    }

    &-items {
      display: block;
      margin-top: 20px;
    }

    &-item {
      display: block;
      margin-top: 12px;
    }

    .main {
      &__title {
        &-bold {
          display: block;
          text-align: center;
          color: #fff;
        }
      }
      &__text {
        display: block;
        text-align: center;
        color: #fff;
        margin-top: 14px;
      }
    }
    &--contacts {
      padding: 0;
      background-color: transparent;

      input, select {
        border: 3px solid #ebebeb;
      }
      .btn-form {
        border: 1px solid #000;
        color: #000;
      }

      .main {
        &__form {
          &-items {
            margin-top: 0;
          }
          &-item {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &__pagination {
    display: block;

    ul {
      display: flex;
      align-items: center;

      li {
        display: inline-block;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: #000;
          font-family: "SFUI Display";
          font-size: 20px;
          font-weight: 400;
          line-height: 1.2;
          transition: .3s;

          &.active {
            color: #c77d7d;
            font-weight: 700;
            transition: .3s;
          }

          &:hover {
            color: #c77d7d;
            transition: .3s;
          }
        }
      }
    }
  }
}

.section-white {
  background-color: #fff;
}

.section-gray {
  background-color: #eff0f1;
}

.parallax-img {
  position: absolute;
  width: 100%;

  &--desk {
    @include r(1200) {
      display: none;
    }
  }
}