.home-hero {
  position: relative;
  z-index: 2;

  &__gallery {
    z-index: 1;
    position: relative;
  }

  .swiper-slide {
    background-color: #fff;
  }

  &__banner {
    display: block;
    height: 600px;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center bottom;
    }

    @include r(550) {
      height: 300px;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 135px;
    z-index: 2;
    @include r(550) {
      padding-top: 87px;
    }
  }

  &__logo {
    max-width: 150px;
    width: 100%;
    display: block;
    margin: 0 auto;

    @include r(550) {
      display: none;
    }
  }

  &__actions {
    display: block;
    text-align: center;
    margin-top: 50px;
    @include r(550) {
      margin-top: 0;
    }

    .btn-dark {
      max-width: 240px;
      display: block;
      margin: 10px auto 0 auto;
    }
  }

}