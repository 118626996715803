.jobs {
  display: block;
  &__holder {
    display: flex;
    margin-top: 50px;

    @include r(900) {
      display: block;
      margin-top: 35px;
    }
  }

  .main {
    &__content {
      padding-bottom: 100px;

      @include r(900) {
        padding-bottom: 50px;
      }
    }
    &__title {
      position: relative;
      z-index: 2;

      h2 {
        color: #fff;
      }
    }
  }

  &__description {
    flex: 0 0 74%;
    position: relative;
    padding: 0 138px 50px 0;
    @include r(900) {
      margin-bottom: 350px;
      padding-right: 0;
      flex: 0 0 100%;
    }
    @include r(400) {
      margin-bottom: 290px;
    }

    &-img {
      position: absolute;
      bottom: -95px;
      left: calc(100% - 160px);
      z-index: 3;
      max-width: 493px;
      width: 100%;

      @include r(1160) {
        transform: none !important;
      }

      @include r(1160) {
        max-width: 400px;
        bottom: -20px;
      }
      @include r(900) {
        left: 50%;
        transform: translateX(-50%) !important;
        bottom: -360px;
        max-width: 355px;
      }

      @include r(400) {
        max-width: 300px;
        bottom: -310px;
      }
    }

    &::before {
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      width: 100vw;
      height: 100vw;
      background-color: #c77d7d;
      content: '';
      position: absolute;
      z-index: 1;

      @include r(700) {
        height: 200vh;
      }
    }

    .main {
      &__text {
        position: relative;
        z-index: 2;
        color: #fff;
        max-width: 90%;

        a {
          color: #021422;
          font-family: "SFUI Display";
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__slogan {
    display: block;
    margin-top: 30px;
    font-style: italic;
    position: relative;
    text-align: right;
    line-height: 1.8;
    z-index: 2;
    font-family: "Gotham Pro";

    @include r(900) {
      display: none;
    }
  }
}