.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 500px;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 70;
  display: none;
  overflow-y: auto;
  max-height: 100%;

  &--request {
    max-width: 332px;
  }

  &--resume {
    max-width: 990px;

    @include r(1000) {
      max-width: 500px;
    }

    .main {
      &__form {
        &-items {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -6px;
          @include r(1000) {
            display: block;
            margin: 0;
          }
        }
        &-item {
          padding: 6px;
          margin: 0;
          @include r(1000) {
            margin: 12px 0 0 0;
            padding: 0;
          }
          &--md {
            flex: 0 0 33.3333333%;
          }
          &--lg {
            flex: 0 0 100%;
          }
        }
      }
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: {
      image: url("#{$images-path}/icons/close-gallery.png");
      repeat: no-repeat;
      size: contain;
    }
    opacity: 1;
    transition: .3s;
    z-index: 2;

    &:hover {
      opacity: .8;
      transition: .3s;
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .6;
  z-index: 50;
}