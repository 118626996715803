.article {

  .container {
    max-width: 950px;
  }
  &__description {
    display: block;
    margin-top: 50px;

    @include r(750) {
      margin-top: 30px;
    }
  }

  .main {
    &__title {
      text-align: left;
      h2 {
        font-size: 35px;
      }
    }
  }

  p.main {
    &__text {
      display: block;
      margin-top: 30px;

      @include r(750) {
        margin-top: 20px;
      }
      img {
        max-width: 385px;
        width: 100%;
        float: right;
        margin: 0 0 20px 120px;

        @include r(1000) {
          margin-left: 50px;
          max-width: 350px;
        }

        @include r(750) {
          float: none;
          margin: 0 auto 20px auto;
          display: block;
        }
      }
    }
  }

}