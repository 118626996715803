// General
@import "general/variables";
@import "general/mixins";
@import "helpers/reset";
@import "general/fonts";
@import "general/base";

// Vendors
@import "vendors/swiper.min";
@import "vendors/lightbox";
@import "vendors/animate";

// Components
@import "components/main";
@import "components/buttons";
@import "components/fields";
@import "components/navigation";

@import "components/header";

@import "components/home-hero";
@import "components/home-about";
@import "components/home-services";
@import "components/home-why-us";
@import "components/home-instagram";
@import "components/home-request";

@import "components/gallery";
@import "components/lifehacks";
@import "components/contacts";
@import "components/article";
@import "components/jobs";
@import "components/jobs-inners";
@import "components/jobs-resume";
@import "components/about";
@import "components/about-request";
@import "components/service";
@import "components/popup";

@import "components/footer";

// Helpers
@import "helpers/animations";