.home-services {
  .container {
    max-width: 870px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 46px;

    &--inline {
      margin: 60px -2% 0 -2%;
      flex-wrap: nowrap;
      @include r(800) {
        flex-wrap: wrap;
      }
      .home-services {
        &__item {
          flex: 0 0 29.4%;
          margin: 0 2%;

          @include r(800) {
            flex: 0 0 46%;
            margin: 2%;
          }
          @include r(800) {
            flex: 100%;
            margin: 0;
          }
        }
      }
    }
  }
  &__item {
    position: relative;
    flex: 0 0 50%;
    padding: 36px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0);
    z-index: 1;
    transition: .3s;

    @include r(550) {
      flex: 0 0 100%;

      &:nth-child(3) {
        order: 4;
      }

      &:nth-child(4) {
        order: 3;
      }
    }

    &:hover {
      transform: scale(1.07);
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.75);
      z-index: 2;
      transition: .3s;
    }

    &--blue {
      background-color: #38445c;
    }

    &--red {
      background-color: #b76364;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 62px;

      img {
        max-width: 66px;
      }
    }

    p {
      flex: 0 0 100%;
      line-height: 1.3;
      color: #fff;
      display: block;
      text-align: center;
      margin-top: 18px;
    }
  }
}