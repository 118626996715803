.home-why-us {
  .container {
    max-width: 1000px;
  }
  &__items {
    display: block;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-top: 46px;

    @include r(550) {
      display: block;
    }

    &-icon {
      flex: 0 0 76px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include r(550) {
        margin-bottom: 20px;
      }

      img {
        max-width: 100%;
        @include r(550) {
          max-width: 76px;
        }
      }
    }
    &-description {
      flex: 1 1 auto;
      padding-left: 88px;

      @include r(900) {
        padding-left: 40px;
      }
      @include r(550) {
        text-align: center;
        padding: 0;
      }

      p {
        display: block;
        margin-top: 12px;
      }
    }
  }
  .main {
    &__content {
      position: relative;
      .parallax-img {
        right: -120px;
        bottom: -870px;
        max-width: 308px;
        transform: rotate(10deg);
      }
    }
  }
}