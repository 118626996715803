@font-face {
  font-family: "SFUI Display";
  src: url('#{$font-path}/SFDisplay/SFUIDisplay-Bold.eot');
  src: url('#{$font-path}/SFDisplay/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Bold.woff2') format('woff2'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Bold.woff') format('woff'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Bold.ttf') format('truetype'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Bold.svg#SFUIDisplay-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SFUI Display";
  src: url('#{$font-path}/SFDisplay/SFUIDisplay-Regular.eot');
  src: url('#{$font-path}/SFDisplay/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Regular.woff2') format('woff2'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Regular.woff') format('woff'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Regular.ttf') format('truetype'),
  url('#{$font-path}/SFDisplay/SFUIDisplay-Regular.svg#SFUIDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url('#{$font-path}/Gotham/GothamPro-Bold.eot');
  src: url('#{$font-path}/Gotham/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Gotham/GothamPro-Bold.woff2') format('woff2'),
  url('#{$font-path}/Gotham/GothamPro-Bold.woff') format('woff'),
  url('#{$font-path}/Gotham/GothamPro-Bold.ttf') format('truetype'),
  url('#{$font-path}/Gotham/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url('#{$font-path}/Gotham/GothamPro-Medium.eot');
  src: url('#{$font-path}/Gotham/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Gotham/GothamPro-Medium.woff2') format('woff2'),
  url('#{$font-path}/Gotham/GothamPro-Medium.woff') format('woff'),
  url('#{$font-path}/Gotham/GothamPro-Medium.ttf') format('truetype'),
  url('#{$font-path}/Gotham/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url('#{$font-path}/Gotham/GothamPro-Light.eot');
  src: url('#{$font-path}/Gotham/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Gotham/GothamPro-Light.woff2') format('woff2'),
  url('#{$font-path}/Gotham/GothamPro-Light.woff') format('woff'),
  url('#{$font-path}/Gotham/GothamPro-Light.ttf') format('truetype'),
  url('#{$font-path}/Gotham/GothamPro-Light.svg#GothamPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url('#{$font-path}/Gotham/GothamPro-LightItalic.eot');
  src: url('#{$font-path}/Gotham/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Gotham/GothamPro-LightItalic.woff2') format('woff2'),
  url('#{$font-path}/Gotham/GothamPro-LightItalic.woff') format('woff'),
  url('#{$font-path}/Gotham/GothamPro-LightItalic.ttf') format('truetype'),
  url('#{$font-path}/Gotham/GothamPro-LightItalic.svg#GothamPro-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

