.home-about {
  position: relative;
  z-index: 1;
  margin-top: -40px;

  .container {
    max-width: 1040px;
  }

  &__content {
    padding: 70px 0;

    @include r(550) {
      padding: 60px 0 50px 0;
    }
  }

  .main {
    &__title {
      img {
        max-width: 70px;
        display: block;
        margin-bottom: 40px;

        @include r(550) {
          margin-bottom: 20px;
        }
      }
    }
    &__text {
      text-align: center;
      margin-top: 25px;
    }
    &__content {
      position: relative;
      .parallax-img {
        left: 0;
        bottom: -270px;
        max-width: 334px;
        transform: rotate(-15deg);
      }
    }
  }
}